import React, { useState, useEffect } from 'react';
import { Table, Layout, Button, Card, Upload, message, Modal, Input, Typography, Space, Form, Alert, Menu } from 'antd';
import { 
  UploadOutlined, ArrowLeftOutlined, AudioOutlined, FileTextOutlined, SettingOutlined, 
  HistoryOutlined, LogoutOutlined, HomeOutlined 
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/auth';  // 添加这行

const { Header, Content, Footer } = Layout;

function SubtitleManager() {
  const [subtitles, setSubtitles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subtitleFile, setSubtitleFile] = useState(null);
  const navigate = useNavigate();
  const [editingName, setEditingName] = useState(null);

  const fetchSubtitles = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/subtitles/list', {
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        setSubtitles(data.subtitles);
      } else {
        message.error('获取字幕列表失败');
      }
    } catch (error) {
      console.error('获取字幕列表错误:', error);
      message.error('获取字幕列表失败');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubtitles();
  }, []);

  const handleSubtitleUpload = async () => {
    if (!subtitleFile) {
      message.warning('请先选择字幕文件');
      return;
    }

    const formData = new FormData();
    formData.append('subtitle', subtitleFile);

    try {
      const response = await fetch('/api/subtitles/upload', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        message.success('字幕文件上传成功！');
        setSubtitleFile(null);
        fetchSubtitles();
      } else {
        message.error(`上传失败：${data.message || '未知错误'}`);
      }
    } catch (error) {
      console.error('上传错误:', error);
      message.error('上传过程中发生错误');
    }
  };

  const handleDeleteSubtitle = async (id) => {
    Modal.confirm({
      title: '确认删除',
      content: '确定要删除这个字幕文件吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        try {
          const response = await fetch(`/api/subtitles/${id}`, {
            method: 'DELETE',
            credentials: 'include',
          });
          const data = await response.json();
          if (data.success) {
            message.success('删除成功');
            fetchSubtitles();
          } else {
            message.error('删除失败');
          }
        } catch (error) {
          console.error('删除错误:', error);
          message.error('删除过程中发生错误');
        }
      },
    });
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('退出失败:', error);
      message.error('退出失败，请重试');
    }
  };

  const columns = [
    {
      title: '文件名',
      dataIndex: 'filename',
      key: 'filename',
      render: (text, record) => (
        editingName === record._id ? (
          <Input
            defaultValue={text}
            onPressEnter={(e) => handleSubtitleRename(record._id, e.target.value)}
            onBlur={() => setEditingName(null)}
            autoFocus
          />
        ) : (
          <span 
            style={{ cursor: 'pointer' }}
            onClick={() => setEditingName(record._id)}
          >
            {text}
          </span>
        )
      )
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button onClick={() => navigate(`/editor/${record._id}`)}>编辑</Button>
          <Button onClick={() => handleDeleteSubtitle(record._id)} style={{ marginLeft: 8 }}>删除</Button>
        </span>
      ),
    },
  ];

  const handleSubtitleRename = async (id, newName) => {
    try {
      const response = await fetch(`/api/subtitles/${id}/rename`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ filename: newName }),
      });
      const data = await response.json();
      if (data.success) {
        message.success('文件名更新成功');
        setEditingName(null);
        fetchSubtitles();
      } else {
        message.error('更新文件名失败');
      }
    } catch (error) {
      console.error('更新文件名错误:', error);
      message.error('更新文件名失败');
    }
  };

  return (
    <Layout className="layout">
      <Header style={{ background: '#001529', padding: '0 24px', display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
          <Space align="center">
            <Button 
              icon={<HomeOutlined />} 
              onClick={() => navigate('/dashboard')}
              style={{ 
                background: '#fff',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                height: '32px'
              }}
            >
              返回首页
            </Button>
            <Typography.Title 
              level={4} 
              style={{ 
                margin: 0,
                color: '#fff',
                lineHeight: '32px'
              }}
            >
              字幕管理
            </Typography.Title>
          </Space>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          items={[
            { key: 'audio', label: '音频管理', icon: <AudioOutlined /> },
            { key: 'subtitle', label: '字幕管理', icon: <FileTextOutlined /> },
            { key: 'rules', label: '规则管理', icon: <SettingOutlined /> },
            { key: 'history', label: '编辑历史', icon: <HistoryOutlined /> },
            { key: 'logout', label: '退出登录', icon: <LogoutOutlined /> }
          ]}
          onClick={({ key }) => {
            switch(key) {
              case 'audio':
                navigate('/audio-manager');
                break;
              case 'subtitle':
                navigate('/subtitle-manager');
                break;
              case 'rules':
                navigate('/rule-manager');
                break;
              case 'history':
                navigate('/history');
                break;
              case 'logout':
                handleLogout();
                break;
            }
          }}
          style={{ background: '#001529' }}
        />
      </Header>
      <Content style={{ padding: '24px', minHeight: 'calc(100vh - 64px - 70px)' }}>
        <Card bordered={false}>
          <div>
            <Upload
              beforeUpload={(file) => {
                setSubtitleFile(file);
                return false;
              }}
              fileList={subtitleFile ? [subtitleFile] : []}
              onRemove={() => {
                setSubtitleFile(null);
                return true;
              }}
            >
              <Button icon={<UploadOutlined />}>选择字幕文件</Button>
            </Upload>
            <Button 
              type="primary" 
              onClick={handleSubtitleUpload} 
              style={{ marginTop: '10px' }}
            >
              上传字幕文件
            </Button>

            <Table
              columns={columns}
              dataSource={subtitles}
              rowKey="_id"
              loading={loading}
              style={{ marginTop: '20px' }}
            />
          </div>
        </Card>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        字幕在线编辑器 ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
}

export default SubtitleManager;