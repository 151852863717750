// LoginPage.js
import React from 'react';
import { Layout, Card, Button, Typography, Space, Divider } from 'antd';
import { 
  GoogleOutlined, 
  WindowsOutlined,
  MessageOutlined,
  LoginOutlined
} from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

function LoginPage() {
  const handleLogin = (provider) => {
    window.location.href = `/auth/${provider}`;
  };

  const loginButtons = [
    {
      key: 'google',
      icon: <GoogleOutlined />,
      text: '使用谷歌账号登录',
      color: '#DB4437',
      provider: 'google'
    },
    {
      key: 'microsoft',
      icon: <WindowsOutlined />,
      text: '使用微软账号登录',
      color: '#00A4EF',
      provider: 'microsoft'
    },
    {
      key: 'discord',
      icon: <MessageOutlined />,
      text: '使用 Discord 登录',
      color: '#7289DA',
      provider: 'discord'
    }
  ];

  return (
    <Layout style={{ minHeight: '100vh', background: '#f0f2f5' }}>
      <Header style={{ 
        background: '#fff', 
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        position: 'fixed',
        width: '100%',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '0 50px'
      }}>
        <Space>
          <LoginOutlined style={{ fontSize: '24px' }} />
          <Title level={4} style={{ margin: 0 }}>字幕在线编辑器</Title>
        </Space>
      </Header>

      <Content style={{ 
        marginTop: 64,
        padding: '50px 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Card 
          style={{ 
            width: '100%',
            maxWidth: 400,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            borderRadius: '8px'
          }}
          bordered={false}
        >
          <div style={{ textAlign: 'center', padding: '20px 0' }}>
            <Title level={2}>欢迎使用</Title>
            <Text type="secondary">
              请选择以下方式登录
            </Text>
          </div>

          <Divider />

          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {loginButtons.map(({ key, icon, text, color, provider }) => (
              <Button
                key={key}
                size="large"
                icon={icon}
                onClick={() => handleLogin(provider)}
                style={{
                  width: '100%',
                  height: '50px',
                  background: color,
                  borderColor: color,
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.opacity = '0.9';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.opacity = '1';
                }}
              >
                {text}
              </Button>
            ))}
          </Space>

          <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <Text type="secondary">
              登录即表示您同意我们的
              <Button type="link">服务条款</Button>
              和
              <Button type="link">隐私政策</Button>
            </Text>
          </div>
        </Card>
      </Content>

      <Footer style={{ 
        textAlign: 'center',
        background: 'transparent'
      }}>
        <Text type="secondary">©2024 字幕在线编辑器 版权所有</Text>
      </Footer>
    </Layout>
  );
}

export default LoginPage;

// 添加自定义样式
const styles = {
  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' }
  },
  '.login-card': {
    animation: 'fadeIn 0.5s ease-out'
  }
};

// 将样式添加到文档中
const styleSheet = document.createElement('style');
styleSheet.textContent = Object.entries(styles).map(([selector, rules]) => {
  if (selector.startsWith('@')) {
    return `${selector} ${JSON.stringify(rules)
      .replace(/[{"}]/g, '')
      .replace(/,/g, ';')}`;
  }
  return `${selector} ${JSON.stringify(rules)
    .replace(/[{"}]/g, '')
    .replace(/,/g, ';')}`;
}).join('\n');
document.head.appendChild(styleSheet);
