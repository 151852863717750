import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Spin, Layout, message } from 'antd';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import SubtitleEditor from './components/SubtitleEditor';
import HistoryPage from './components/HistoryPage';
import AudioManager from './components/AudioManager';
import SubtitleManager from './components/SubtitleManager';
import RuleManager from './components/RuleManager';

const { Content } = Layout;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // 获取当前用户信息
  const fetchCurrentUser = async () => {
    try {
      const response = await fetch('/auth/current_user', {
        credentials: 'include',
      });
      
      if (!response.ok) {
        if (response.status === 401) {
          setIsAuthenticated(false);
          setUser(null);
          return;
        }
        throw new Error('获取用户信息失败');
      }

      const data = await response.json();
      if (data.user) {
        setIsAuthenticated(true);
        setUser(data.user);
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('获取当前用户错误:', error);
      message.error('获取用户信息失败');
      setIsAuthenticated(false);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  // 初始加载时检查用户状态
  useEffect(() => {
    fetchCurrentUser();
  }, []);

  // 定期检查用户会话状态
  useEffect(() => {
    const checkInterval = setInterval(fetchCurrentUser, 5 * 60 * 1000); // 每5分钟检查一次
    return () => clearInterval(checkInterval);
  }, []);

  // 添加全局错误处理
  useEffect(() => {
    const handleError = (error) => {
      console.error('全局错误:', error);
      message.error('发生错误，请刷新页面重试');
    };

    const handleUnauthorized = async (response) => {
      if (response.status === 401) {
        setIsAuthenticated(false);
        setUser(null);
        message.error('会话已过期，请重新登录');
        window.location.href = '/';
      }
    };

    // 拦截所有 fetch 请求
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
      try {
        const response = await originalFetch(...args);
        await handleUnauthorized(response);
        return response;
      } catch (error) {
        handleError(error);
        throw error;
      }
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);

    return () => {
      window.fetch = originalFetch;
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, []);

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <Spin size="large" tip="加载中..." />
      </div>
    );
  }

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Content>
          <Routes>
            <Route 
              path="/" 
              element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />} 
            />
            <Route 
              path="/dashboard" 
              element={isAuthenticated ? <Dashboard user={user} /> : <Navigate to="/" />} 
            />
            <Route 
              path="/editor/:id" 
              element={
                isAuthenticated ? (
                  <SubtitleEditor user={user} />
                ) : (
                  <Navigate to="/" state={{ from: window.location.pathname }} />
                )
              } 
            />
            <Route 
              path="/history" 
              element={isAuthenticated ? <HistoryPage user={user} /> : <Navigate to="/" />} 
            />
            <Route 
              path="/audio-manager" 
              element={isAuthenticated ? <AudioManager user={user} /> : <Navigate to="/" />} 
            />
            <Route 
              path="/subtitle-manager" 
              element={isAuthenticated ? <SubtitleManager user={user} /> : <Navigate to="/" />} 
            />
            <Route 
              path="/rule-manager" 
              element={isAuthenticated ? <RuleManager user={user} /> : <Navigate to="/" />} 
            />
            <Route 
              path="/logout" 
              element={
                <Navigate to="/" replace />
              } 
            />
            <Route 
              path="*" 
              element={
                <div style={{ 
                  textAlign: 'center', 
                  padding: '50px' 
                }}>
                  <h2>404 - 页面不存在</h2>
                  <button 
                    onClick={() => window.history.back()}
                    style={{
                      padding: '8px 16px',
                      border: 'none',
                      borderRadius: '4px',
                      backgroundColor: '#1890ff',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    返回上一页
                  </button>
                </div>
              } 
            />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;