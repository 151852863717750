import React, { useState, useEffect } from 'react';
import { Table, Layout, Button, Card, Upload, message, Modal, Input, Typography, Space, Form, Alert, Menu } from 'antd';
import { 
  UploadOutlined, ArrowLeftOutlined, AudioOutlined, FileTextOutlined, SettingOutlined, 
  HistoryOutlined, LogoutOutlined, HomeOutlined 
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/auth';  // 添加这行

const { Header, Content, Footer } = Layout;

function RuleManager() {
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rulesFile, setRulesFile] = useState(null);
  const [showRuleEditor, setShowRuleEditor] = useState(false);
  const [ruleContent, setRuleContent] = useState('');
  const [editingRuleId, setEditingRuleId] = useState(null);
  const [ruleModalVisible, setRuleModalVisible] = useState(false);
  const navigate = useNavigate();

  const fetchRules = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/subtitles/rules', {
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        setRules(data.rules);
      } else {
        message.error('获取规则列表失败');
      }
    } catch (error) {
      console.error('获取规则列表错误:', error);
      message.error('获取规则列表失败');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRules();
  }, []);

  const handleRulesUpload = async () => {
    if (!rulesFile) {
      message.warning('请先选择替换规则文件');
      return;
    }

    const formData = new FormData();
    formData.append('rules', rulesFile);

    try {
      const response = await fetch('/api/subtitles/upload-rules', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        message.success('替换规则上传成功！');
        setRulesFile(null);
        fetchRules();
      } else {
        message.error(`上传失败：${data.message}`);
      }
    } catch (error) {
      console.error('上传错误:', error);
      message.error('上传过程中发生错误');
    }
  };

  const handleDeleteRule = async (id) => {
    if (!id) {
      message.error('规则ID无效');
      return;
    }
    
    Modal.confirm({
      title: '确认删除',
      content: '确定要删除这个替换规则吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        try {
          const response = await fetch(`/api/subtitles/rules/${id}`, {
            method: 'DELETE',
            credentials: 'include',
          });
          const data = await response.json();
          if (data.success) {
            message.success('删除成功');
            fetchRules();
          } else {
            message.error(data.message || '删除失败');
          }
        } catch (error) {
          console.error('删除错误:', error);
          message.error('删除过程中发生错误');
        }
      },
    });
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('退出失败:', error);
      message.error('退出失败，请重试');
    }
  };

  const columns = [
    {
      title: '规则名',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Button onClick={() => handleEditRule(record)}>编辑</Button>
          <Button onClick={() => handleDeleteRule(record.id)}
            style={{ marginLeft: 8 }}
            danger
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const handleCreateRule = async (values) => {
    try {
      const formData = new FormData();
      const blob = new Blob([values.content], { type: 'text/plain' });
      formData.append('rules', blob, `${values.name}.txt`);

      const response = await fetch('/api/subtitles/upload-rules', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        message.success('规则创建成功');
        setRuleModalVisible(false);
        fetchRules();
      } else {
        message.error(data.message || '创建规则失败');
      }
    } catch (error) {
      console.error('创建规则错误:', error);
      message.error('创建规则失败');
    }
  };

  const handleEditRule = (rule) => {
    setRuleContent(
      rule.rules
        .map(r => `${r.find},${r.replace}`)
        .join('\n')
    );
    setEditingRuleId(rule._id);  // 修改这里：从 rule.id 改为 rule._id
    setShowRuleEditor(true);
  };

  const ruleExample = `# 替换规则示例：
find1,replace1
find2,replace2

# 每行一条规则
# 格式：要查找的文本,要替换的文本
# 例如：
你好,您好
早上好,早安
`;

  return (
    <Layout className="layout">
      <Header style={{ background: '#001529', padding: '0 24px', display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
          <Space align="center">
            <Button 
              icon={<HomeOutlined />} 
              onClick={() => navigate('/dashboard')}
              style={{ 
                background: '#fff',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                height: '32px'
              }}
            >
              返回首页
            </Button>
            <Typography.Title 
              level={4} 
              style={{ 
                margin: 0,
                color: '#fff',
                lineHeight: '32px'
              }}
            >
              替换规则管理
            </Typography.Title>
          </Space>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          items={[
            { key: 'audio', label: '音频管理', icon: <AudioOutlined /> },
            { key: 'subtitle', label: '字幕管理', icon: <FileTextOutlined /> },
            { key: 'rules', label: '规则管理', icon: <SettingOutlined /> },
            { key: 'history', label: '编辑历史', icon: <HistoryOutlined /> },
            { key: 'logout', label: '退出登录', icon: <LogoutOutlined /> }
          ]}
          onClick={({ key }) => {
            switch(key) {
              case 'audio':
                navigate('/audio-manager');
                break;
              case 'subtitle':
                navigate('/subtitle-manager');
                break;
              case 'rules':
                navigate('/rule-manager');
                break;
              case 'history':
                navigate('/history');
                break;
              case 'logout':
                handleLogout();
                break;
            }
          }}
          style={{ background: '#001529' }}
        />
      </Header>
      <Content style={{ padding: '24px', minHeight: 'calc(100vh - 64px - 70px)' }}>
        <Card bordered={false}>
          <div>
            {/* 添加一个容器来放置上传和创建按钮 */}
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              marginBottom: '20px' 
            }}>
              <div>
                <Upload
                  beforeUpload={(file) => {
                    const isTxtOrJson = file.type === 'text/plain' || file.type === 'application/json';
                    if (!isTxtOrJson) {
                      message.error('请上传 TXT 或 JSON 格式的规则文件！');
                      return false;
                    }
                    setRulesFile(file);
                    return false;
                  }}
                  accept=".txt,.json"
                  fileList={rulesFile ? [rulesFile] : []}
                >
                  <Button icon={<UploadOutlined />}>选择替换规则文件</Button>
                </Upload>
                <Button 
                  type="primary" 
                  onClick={handleRulesUpload} 
                  style={{ marginTop: '10px' }}
                >
                  上传替换规则文件
                </Button>
              </div>
              <Button 
                type="primary" 
                onClick={() => setRuleModalVisible(true)}
              >
                创建新规则
              </Button>
            </div>

            {/* 表格部分 */}
            <Table
              columns={columns}
              dataSource={rules}
              rowKey="id"
              loading={loading}
            />    
                
            <Modal
              title="创建新规则"
              visible={ruleModalVisible}
              onCancel={() => setRuleModalVisible(false)}
              footer={null}
            >
              <div style={{ marginBottom: 16 }}>
                <p>请输入替换规则，每行一条，格式：原文,替换文</p>
                <p>示例：</p>
                <pre style={{ background: '#f5f5f5', padding: 8 }}>
                  ブランド,brand{'\n'}
                  商品,product{'\n'}
                  会社,company
                </pre>
              </div>
              <Form onFinish={handleCreateRule}>
                <Form.Item
                  name="name"
                  label="规则名称"
                  rules={[{ required: true, message: '请输入规则名称' }]}
                >
                  <Input placeholder="请输入规则名称" />
                </Form.Item>
                <Form.Item
                  name="content"
                  label="规则内容"
                  rules={[{ required: true, message: '请输入规则内容' }]}
                >
                  <Input.TextArea 
                    rows={10} 
                    placeholder="请输入规则内容，每行一条规则，格式：原文,替换文"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    创建规则
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              title="编辑替换规则"
              open={showRuleEditor}
              onOk={async () => {
                try {
                  const ruleLines = ruleContent.trim().split('\n');
                  const invalidLines = ruleLines.filter(line => {
                    const parts = line.trim().split(',');
                    return parts.length !== 2 || !parts[0].trim() || !parts[1].trim();
                  });

                  if (invalidLines.length > 0) {
                    message.error('规则格式不正确，请确保每行都是"原文,替换文"的格式');
                    return;
                  }

                  const formData = new FormData();
                  const blob = new Blob([ruleContent], { type: 'text/plain' });
                  formData.append('rules', blob, 'rules.txt');

                  if (editingRuleId) {
                    formData.append('ruleId', editingRuleId);
                  }

                  const response = await fetch('/api/subtitles/upload-rules', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                  });

                  const data = await response.json();
                  if (data.success) {
                    message.success(editingRuleId ? '规则更新成功' : '规则保存成功');
                    setShowRuleEditor(false);
                    setRuleContent('');
                    setEditingRuleId(null);
                    fetchRules();
                  } else {
                    message.error(data.message || '保存规则失败');
                  }
                } catch (error) {
                  console.error('保存规则错误:', error);
                  message.error('保存规则失败');
                }
              }}
              onCancel={() => {
                setShowRuleEditor(false);
                setRuleContent('');
                setEditingRuleId(null);
              }}
            >
              <Input.TextArea
                value={ruleContent}
                onChange={e => setRuleContent(e.target.value)}
                placeholder="输入规则内容..."
                rows={10}
              />
            </Modal>
          </div>
        </Card>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        字幕在线编辑器 ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
}

export default RuleManager;