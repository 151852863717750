const fetchWithCredentials = (url, options = {}) => {
  return fetch(url, {
    ...options,
    credentials: 'include',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json'
    }
  });
};

export const getCurrentUser = async () => {
  try {
    const response = await fetchWithCredentials('/auth/current_user');
    if (!response.ok) throw new Error('认证失败');
    return await response.json();
  } catch (error) {
    console.error('获取用户信息失败:', error);
    return { user: null };
  }
};

export const loginWithGoogle = () => {
  window.location.href = '/auth/google';
};

export const logout = async () => {
  try {
    const response = await fetchWithCredentials('/auth/logout');
    if (!response.ok) throw new Error('退出失败');
    return await response.json();
  } catch (error) {
    console.error('退出失败:', error);
    throw error;
  }
};