import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Layout, Table, Space, Button, message, Modal, 
  Typography, Card, Tag, Tooltip 
} from 'antd';
import { 
  EditOutlined, DeleteOutlined, HistoryOutlined,
  FileTextOutlined, ClockCircleOutlined, HomeOutlined
} from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

function HistoryPage() {
  const [subtitles, setSubtitles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubtitle, setSelectedSubtitle] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/subtitles/list', { // 修改这里
        credentials: 'include',
      });
      const data = await response.json();
      
      if (data.success) {
        setSubtitles(data.subtitles);
      } else {
        message.error('获取历史记录失败');
      }
    } catch (error) {
      console.error('获取历史记录错误:', error);
      message.error('获取历史记录失败');
    } finally {
      setLoading(false);
    }
  };

   // 获取单个字幕的历史记录
  const fetchSubtitleHistory = async (id) => {
    try {
      const response = await fetch(`/api/subtitles/${id}/history`, {
        credentials: 'include',
      });
      const data = await response.json();
      
      if (data.success) {
        return data.history;
      } else {
        message.error('获取修改历史失败');
        return [];
      }
    } catch (error) {
      console.error('获取修改历史错误:', error);
      message.error('获取修改历史失败');
      return [];
    }
  };

  const handleEdit = (id) => {
    navigate(`/editor/${id}`);
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: '确认删除',
      content: '确定要删除这个字幕文件吗？此操作不可恢复。',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        try {
          const response = await fetch(`/api/subtitles/${id}`, {
            method: 'DELETE',
            credentials: 'include',
          });

          const data = await response.json();
          if (data.success) {
            message.success('删除成功');
            setSubtitles(subtitles.filter(s => s._id !== id));
          } else {
            message.error(data.message || '删除失败');
          }
        } catch (error) {
          console.error('删除错误:', error);
          message.error('删除操作失败');
        }
      },
    });
  };

  const handleViewHistory = async (subtitle) => {
    const history = await fetchSubtitleHistory(subtitle._id);
    setSelectedSubtitle({
      ...subtitle,
      history
    });
  };

  const columns = [
    {
      title: '文件名',
      dataIndex: 'filename',
      key: 'filename',
      render: (text) => (
        <Space>
          <FileTextOutlined />
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (
        <Tooltip title={new Date(text).toLocaleString()}>
          <Space>
            <ClockCircleOutlined />
            <span>{new Date(text).toLocaleDateString()}</span>
          </Space>
        </Tooltip>
      ),
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    },
    {
      title: '修改次数',
      dataIndex: 'history',
      key: 'history',
      render: (history) => (
        <Tag color="blue">
          {history?.length || 0} 次修改
        </Tag>
      ),
    },
    {
      title: '最后修改',
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      render: (text) => text ? (
        <Tooltip title={new Date(text).toLocaleString()}>
          {new Date(text).toLocaleDateString()}
        </Tooltip>
      ) : '-',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            type="primary" 
            icon={<EditOutlined />}
            onClick={() => handleEdit(record._id)}
          >
            编辑
          </Button>
          <Button
            icon={<HistoryOutlined />}
            onClick={() => handleViewHistory(record)}
          >
            历史
          </Button>
          <Button 
            danger 
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id)}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Layout className="history-layout">
      <Header style={{ background: '#001529', padding: '0 24px', display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
          <Space align="center">
            <Button 
              icon={<HomeOutlined />} 
              onClick={() => navigate('/dashboard')}
              style={{ 
                background: '#fff',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                height: '32px'
              }}
            >
              返回首页
            </Button>
            <Typography.Title 
              level={4} 
              style={{ 
                margin: 0,
                color: '#fff',
                lineHeight: '32px'
              }}
            >
              编辑历史
            </Typography.Title>
          </Space>
        </div>
      </Header>
      <Content style={{ padding: '24px' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <Card bordered={false}>
            <Table
              columns={columns}
              dataSource={subtitles}
              rowKey="_id"
              loading={loading}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total) => `共 ${total} 条记录`,
              }}
            />
          </Card>
        </div>

        <Modal
          title="修改历史"
          open={!!selectedSubtitle}
          onCancel={() => setSelectedSubtitle(null)}
          footer={null}
          width={800}
        >
          {selectedSubtitle && (
            <Table
              dataSource={selectedSubtitle.history}
              columns={[
                {
                  title: '修改时间',
                  dataIndex: 'timestamp',
                  key: 'timestamp',
                  render: (text) => new Date(text).toLocaleString(),
                },
                {
                  title: '操作类型',
                  dataIndex: 'action',
                  key: 'action',
                  render: (text) => (
                    <Tag color={text === 'replace' ? 'green' : 'blue'}>
                      {text === 'replace' ? '应用替换' : '手动修改'}
                    </Tag>
                  ),
                },
              ]}
              rowKey="timestamp"
              pagination={false}
              scroll={{ y: 400 }}
            />
          )}
        </Modal>
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        ©2024 字幕在线编辑器
      </Footer>
    </Layout>
  );
}

export default HistoryPage;