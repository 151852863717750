import React, { useState, useEffect } from 'react';
import { 
  Layout, Menu, Upload, Button, message, Table, Empty, Space, Modal, Card, Row, Col, Input, Spin, Typography, Form, Alert
} from 'antd';
import { 
  UploadOutlined, HistoryOutlined, LogoutOutlined, 
  EditOutlined, DeleteOutlined, FileTextOutlined,
  AudioOutlined, SettingOutlined, ArrowLeftOutlined 
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/auth';

const { Header, Content, Footer } = Layout;

function Dashboard() {
  const [subtitleFile, setSubtitleFile] = useState(null);
  const [rulesFile, setRulesFile] = useState(null);
  const [rules, setRules] = useState([]);
  const [rulesFilename, setRulesFilename] = useState(null);
  const [showRuleEditor, setShowRuleEditor] = useState(false);
  const [ruleContent, setRuleContent] = useState('');
  const [subtitles, setSubtitles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rulesLoading, setRulesLoading] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState(null);
  const [editingName, setEditingName] = useState(null);
  const [selectedSubtitleId, setSelectedSubtitleId] = useState(null);
  const [editingRuleId, setEditingRuleId] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();


  const handleAudioUpload = async () => {
    if (!audioFile) {
      message.warning('请先选择音频文件');
      return;
    }

    const formData = new FormData();
    formData.append('audio', audioFile);

    try {
      const response = await fetch('/api/audio/transcribe', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        message.success('音频转录成功！');
        setAudioFile(null);
        fetchSubtitles(); // 更新字幕列表
      } else {
        message.error(`转录失败：${data.message || '未知错误'}`);
      }
    } catch (error) {
      console.error('转录错误:', error);
      message.error('转录过程中发生错误');
    }
  };

  // 修改规则表格列定义
  const ruleColumns = [
    {
      title: '规则名称',
      dataIndex: 'filename',
      key: 'filename',
      render: (text, record) => (
        editingName === record.id ? (
          <Input
            defaultValue={text}
            onPressEnter={(e) => handleRuleRename(record.id, e.target.value)}
            onBlur={() => setEditingName(null)}
            autoFocus
          />
        ) : (
          <span 
            style={{ cursor: 'pointer' }}
            onClick={() => setEditingName(record.id)}
          >
            {text}
          </span>
        )
      )
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button 
            type="link" 
            onClick={() => handleEditRule(record)}
            icon={<EditOutlined />}
          >
            编辑
          </Button>
          <Button 
            type="link" 
            danger
            onClick={() => handleDeleteRule(record.id)}
            icon={<DeleteOutlined />}
          >
            删除
          </Button>
          <Button
            type="link"
            onClick={() => handleApplyRule(record.id)}
            disabled={!selectedSubtitleId}
          >
            {selectedSubtitleId ? '应用规则' : '请先选择字幕'}
          </Button>
        </Space>
      ),
    },
  ];

  // 重命名规则
  const handleRuleRename = async (id, newName) => {
    try {
      const response = await fetch(`/api/subtitles/rules/${id}/rename`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ filename: newName }),
      });
      const data = await response.json();
      if (data.success) {
        message.success('规则名称更新成功');
        setEditingName(null);
        fetchRules();
      } else {
        message.error('更新规则名称失败');
      }
    } catch (error) {
      console.error('更新规则名称错误:', error);
      message.error('更新规则名称失败');
    }
  };

  // 编辑规则
  const handleEditRule = (rule) => {
    setRuleContent(
      rule.rules
        .map(r => `${r.find},${r.replace}`)
        .join('\n')
    );
    setEditingRuleId(rule.id); // 设置正在编辑的规则 ID
    setShowRuleEditor(true);
  };

  // 删除规则
  const handleDeleteRule = async (id) => {
    try {
      const response = await fetch(`/api/subtitles/rules/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        message.success('规则删除成功');
        fetchRules();
      } else {
        message.error('删除规则失败');
      }
    } catch (error) {
      console.error('删除规则错误:', error);
      message.error('删除规则失败');
    }
  };

  // 应用规则
  const handleApplyRule = async (ruleId) => {
    if (!selectedSubtitleId) {
      message.warning('请先选择要处理的字幕文件');
      return;
    }

    try {
      const response = await fetch('/api/subtitles/replace', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          subtitleId: selectedSubtitleId,
          ruleId: ruleId
        }),
      });
      const data = await response.json();
      if (data.success) {
        message.success('替换规则应用成功');
        // 导航到编辑器页面
        navigate(`/editor/${selectedSubtitleId}`);
      } else {
        message.error(data.message || '应用规则失败');
      }
    } catch (error) {
      console.error('应用规则错误:', error);
      message.error('应用规则失败');
    }
  };

  // 获取字幕列表
  const fetchSubtitles = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/subtitles/list', {
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        setSubtitles(data.subtitles);
      } else {
        message.error('获取字幕列表失败');
      }
    } catch (error) {
      console.error('获取字幕列表错误:', error);
      message.error('获取字幕列表失败');
    } finally {
      setLoading(false);
    }
  };

  // 获取当前规则
  const fetchRules = async () => {
    try {
      setRulesLoading(true);
      const response = await fetch('/api/subtitles/rules', {
        credentials: 'include'
      });
      const data = await response.json();
      if (data.success) {
        setRules(data.rules); // 直接使用返回的规则数组
        // 不再需要设置 rulesFilename，因为文件名现在包含在每个规则对象中
      } else {
        message.error('获取规则失败');
      }
    } catch (error) {
      console.error('获取规则失败:', error);
      message.error('获取规则失败');
    } finally {
      setRulesLoading(false);
    }
  };

  useEffect(() => {
    fetchSubtitles();
    fetchRules();
  }, []);

  const handleSubtitleUpload = async () => {
    if (!subtitleFile) {
      message.warning('请先选择字幕文件');
      return;
    }

    if (subtitleFile.size === 0) {
      message.error('文件内容为空');
      return;
    }

    const formData = new FormData();
    formData.append('subtitle', subtitleFile);

    try {
      const response = await fetch('/api/subtitles/upload', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (response.ok && data.success) {
        message.success('字幕文件上传成功！');
        setSubtitleFile(null);
        fetchSubtitles();
      } else {
        message.error(`上传失败：${data.message || '未知错误'}`);
      }
    } catch (error) {
      console.error('上传错误:', error);
      message.error('上传过程中发生错误');
    }
  };

  const handleRulesUpload = async () => {
    if (!rulesFile) {
      message.warning('请先选择替换规则文件');
      return;
    }

    const formData = new FormData();
    formData.append('rules', rulesFile);

    try {
      const response = await fetch('/api/subtitles/upload-rules', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        message.success('替换规则上传成功！');
        setRulesFile(null);
        fetchRules();
      } else {
        message.error(`上传失败：${data.message}`);
      }
    } catch (error) {
      console.error('上传错误:', error);
      message.error('上传过程中发生错误');
    }
  };

  // 添加字幕重命名函数
  const handleSubtitleRename = async (id, newName) => {
    try {
      const response = await fetch(`/api/subtitles/${id}/rename`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ filename: newName }),
      });
      const data = await response.json();
      if (data.success) {
        message.success('文件名更新成功');
        setEditingName(null);
        fetchSubtitles();
      } else {
        message.error('更新文件名失败');
      }
    } catch (error) {
      console.error('更新文件名错误:', error);
      message.error('更新文件名失败');
    }
  };

  // 添加 handleLogout 函数
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('退出失败:', error);
      message.error('退出失败，请重试');
    }
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: '确认删除',
      content: '确定要删除这个字幕文件吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        try {
          const response = await fetch(`/api/subtitles/${id}`, {
            method: 'DELETE',
            credentials: 'include',
          });
          const data = await response.json();
          if (data.success) {
            message.success('删除成功');
            fetchSubtitles();
          } else {
            message.error('删除失败');
          }
        } catch (error) {
          console.error('删除错误:', error);
          message.error('删除过程中发生错误');
        }
      },
    });
  };

  const columns = [
    {
      title: '文件名',
      dataIndex: 'filename',
      key: 'filename',
      render: (text, record) => (
        editingName === record._id ? (
          <Input
            defaultValue={text}
            onPressEnter={(e) => handleSubtitleRename(record._id, e.target.value)}
            onBlur={() => setEditingName(null)}
            autoFocus
          />
        ) : (
          <span 
            style={{ cursor: 'pointer' }}
            onClick={() => setEditingName(record._id)}
          >
            <FileTextOutlined /> {text}
          </span>
        )
      ),
    },
    {
      title: '上传时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button 
            type="primary" 
            icon={<EditOutlined />}
            onClick={() => navigate(`/editor/${record._id}`)}
          >
            编辑
          </Button>
          <Button 
            danger 
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id)}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const menuItems = [
    { label: '编辑历史', key: 'history', icon: <HistoryOutlined /> },
    { label: '退出登录', key: 'logout', icon: <LogoutOutlined /> },
  ];

  // 修改 handleMenuClick 函数
  const handleMenuClick = async ({ key }) => {
    if (key === 'history') {
      navigate('/history');
    } else if (key === 'logout') {
      try {
        await logout();
        navigate('/');
      } catch (error) {
        console.error('退出失败:', error);
        message.error('退出失败，请重试');
      }
    }
  };

  return (
    <Layout className="dashboard-layout">
      <Header style={{ background: '#001529', padding: '0 24px' }}>
        <div style={{ 
          float: 'left', 
          color: '#fff', 
          fontSize: '20px',
          fontWeight: 'bold'
        }}>
          字幕在线编辑器
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          items={[
            { key: 'audio', label: '音频管理', icon: <AudioOutlined /> },
            { key: 'subtitle', label: '字幕管理', icon: <FileTextOutlined /> },
            { key: 'rules', label: '规则管理', icon: <SettingOutlined /> },
            { key: 'history', label: '编辑历史', icon: <HistoryOutlined /> },
            { key: 'logout', label: '退出登录', icon: <LogoutOutlined /> }
          ]}
          onClick={({ key }) => {
            switch(key) {
              case 'audio':
                navigate('/audio-manager');
                break;
              case 'subtitle':
                navigate('/subtitle-manager');
                break;
              case 'rules':
                navigate('/rule-manager');
                break;
              case 'history':
                navigate('/history');
                break;
              case 'logout':
                handleLogout();
                break;
            }
          }}
          style={{ 
            float: 'right',
            background: '#001529'  // 添加这行
          }}
        />
      </Header>
      <Content style={{ padding: '24px' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          {/* 音频转录功能 - 单独一行 */}
          <Row style={{ marginBottom: '24px' }}>
            <Col span={24}>
              <Card 
                title={
                  <Space>
                    <AudioOutlined style={{ fontSize: '24px' }} />
                    <Typography.Title level={3} style={{ margin: 0 }}>音频转录</Typography.Title>
                  </Space>
                } 
                bordered={false}
                className="audio-upload-card"
              >
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  padding: '40px 0'
                }}>
                  <Upload
                    accept="audio/*"
                    beforeUpload={(file) => {
                      setAudioFile(file);
                      return false;
                    }}
                    fileList={audioFile ? [audioFile] : []}
                    maxCount={1}
                    onRemove={() => {
                      setAudioFile(null);
                      return true;
                    }}
                  >
                    <Button icon={<UploadOutlined />} size="large">
                      选择音频文件
                    </Button>
                  </Upload>
                  <Button 
                    type="primary" 
                    size="large"
                    onClick={handleAudioUpload} 
                    loading={uploading}
                    disabled={!audioFile}
                    style={{ marginTop: '20px', minWidth: '200px' }}
                  >
                    上传并转录为字幕
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginBottom: '24px' }}>
            <Col span={12}>
              <Card 
                title={
                  <Space>
                    <FileTextOutlined />
                    上传字幕文件
                  </Space>
                } 
                bordered={false}
              >
                <Upload
                  beforeUpload={(file) => {
                    setSubtitleFile(file);
                    return false;
                  }}
                  fileList={subtitleFile ? [subtitleFile] : []}
                  onRemove={() => {
                    setSubtitleFile(null);
                    return true;
                  }}
                >
                  <Button icon={<UploadOutlined />}>选择字幕文件</Button>
                </Upload>
                <Button 
                  type="primary" 
                  onClick={handleSubtitleUpload} 
                  style={{ marginTop: '10px' }}
                >
                  上传字幕文件
                </Button>
              </Card>
            </Col>
            <Col span={12}>
              <Card 
                title={
                  <Space>
                    <SettingOutlined />
                    上传替换规则文件
                  </Space>
                } 
                bordered={false}
              >
                <Upload
                  beforeUpload={(file) => {
                    const isTxtOrJson = file.type === 'text/plain' || file.type === 'application/json';
                    if (!isTxtOrJson) {
                      message.error('请上传 TXT 或 JSON 格式的规则文件！');
                      return false;
                    }
                    setRulesFile(file);
                    return false;
                  }}
                  accept=".txt,.json"
                  fileList={rulesFile ? [rulesFile] : []}
                >
                  <Button icon={<UploadOutlined />}>选择替换规则文件</Button>
                </Upload>
                <Button 
                  type="primary" 
                  onClick={handleRulesUpload} 
                  style={{ marginTop: '10px' }}
                >
                  上传替换规则文件
                </Button>
              </Card>
            </Col>
          </Row>

          <Card 
            title="最新字幕" 
            extra={<Button type="link" onClick={() => navigate('/subtitle-manager')}>查看全部</Button>}
            style={{ marginBottom: '24px' }}
          >
            <Table 
              columns={columns} 
              dataSource={subtitles}
              rowKey="_id"
              loading={loading}
              rowSelection={{
                type: 'radio',
                selectedRowKeys: selectedSubtitleId ? [selectedSubtitleId] : [],
                onChange: (selectedRowKeys) => {
                  setSelectedSubtitleId(selectedRowKeys[0]);
                },
                columnTitle: '选择',
                columnWidth: 60,
              }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total) => `共 ${total} 个文件`
              }}
            />
            {selectedSubtitleId && (
              <div style={{ marginTop: 16, color: '#666' }}>
                已选择字幕文件，可以在下方规则列表中应用替换规则
              </div>
            )}
          </Card>

          {/* 替换原有的规则显示部分 */}
          <Card 
            title={
              <Space>
                替换规则管理
                {rulesLoading && <Spin size="small" />}
              </Space>
            }
            bordered={false}
            style={{ marginTop: '24px' }}
            extra={
              <Button 
                type="primary" 
                onClick={() => {
                  setRuleContent('');
                  setShowRuleEditor(true);
                }}
              >
                创建新规则
              </Button>
            }
          >
            <Table
              loading={rulesLoading}
              dataSource={rules}
              columns={ruleColumns}
              pagination={false}
              size="small"
              bordered
              rowKey="id"
            />
          </Card>
          
            <Modal
              title="编辑替换规则"
              open={showRuleEditor}
              onOk={async () => {
                try {
                  const ruleLines = ruleContent.trim().split('\n');
                  const invalidLines = ruleLines.filter(line => {
                    const parts = line.trim().split(',');
                    return parts.length !== 2 || !parts[0].trim() || !parts[1].trim();
                  });

                  if (invalidLines.length > 0) {
                    message.error('规则格式不正确，请确保每行都是"原文,替换文"的格式');
                    return;
                  }

                  const formData = new FormData();
                  const blob = new Blob([ruleContent], { type: 'text/plain' });
                  formData.append('rules', blob, 'rules.txt');

                  // 如果是编辑现有规则，添加规则ID
                  if (editingRuleId) {
                    formData.append('ruleId', editingRuleId);
                  }

                  const response = await fetch('/api/subtitles/upload-rules', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                  });

                  const data = await response.json();
                  if (data.success) {
                    message.success(editingRuleId ? '规则更新成功' : '规则保存成功');
                    setShowRuleEditor(false);
                    setRuleContent('');
                    setEditingRuleId(null); // 清除编辑状态
                    fetchRules();
                  } else {
                    message.error(data.message || '保存规则失败');
                  }
                } catch (error) {
                  console.error('保存规则错误:', error);
                  message.error('保存规则失败');
                }
              }}
              onCancel={() => {
                setShowRuleEditor(false);
                setRuleContent('');
                setEditingRuleId(null); // 清除编辑状态
              }}
            >
              <div style={{ marginBottom: 16 }}>
                <p>请输入替换规则，每行一条，格式：原文,替换文</p>
                <p>示例：</p>
                <pre style={{ background: '#f5f5f5', padding: 8 }}>
                  ブランド,brand{'\n'}
                  商品,product{'\n'}
                  会社,company
                </pre>
              </div>
              <Input.TextArea
                value={ruleContent}
                onChange={e => setRuleContent(e.target.value)}
                placeholder="输入规则内容..."
                rows={10}
                onPaste={(e) => {
                  // 处理从 Excel 粘贴的内容
                  const clipboardData = e.clipboardData;
                  const pastedData = clipboardData.getData('text');
                  // 如果包含制表符，转换为逗号
                  if (pastedData.includes('\t')) {
                    e.preventDefault();
                    const convertedData = pastedData
                      .split('\n')
                      .map(line => line.replace('\t', ','))
                      .join('\n');
                    setRuleContent(convertedData);
                  }
                }}
              />
              <div style={{ marginTop: 8, color: '#666' }}>
                提示：可以直接从 Excel 复制两列内容粘贴到这里
              </div>
            </Modal>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        ©2024 字幕在线编辑器
      </Footer>
    </Layout>
  );
}

export default Dashboard;