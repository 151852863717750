import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Button, message, Space, Spin, Modal, Select, Typography } from 'antd';
import { SaveOutlined, UndoOutlined, ExportOutlined, ArrowLeftOutlined, SwapOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;

function SubtitleEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [originalContent, setOriginalContent] = useState('');
  const [modifiedContent, setModifiedContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [fileName, setFileName] = useState('');
  const [rules, setRules] = useState([]);
  const [selectedRuleId, setSelectedRuleId] = useState(null);
  const [applyingRule, setApplyingRule] = useState(false);
  const [lastSavedContent, setLastSavedContent] = useState(''); // 新增：保存最后一次保存的内容
  const originalRef = useRef(null);
  const modifiedRef = useRef(null);

  useEffect(() => {
    fetchSubtitle();
  }, [id]);

  // 添加获取规则列表的函数
  const fetchSubtitle = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/subtitles/${id}`, {
        credentials: 'include',
      });
      const data = await response.json();
      
      if (data.success) {
        setOriginalContent(data.subtitle.originalContent);
        setModifiedContent(data.subtitle.modifiedContent);
        setLastSavedContent(data.subtitle.modifiedContent); // 保存最后一次保存的内容
        setFileName(data.subtitle.filename);
      } else {
        message.error('获取字幕内容失败');
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('获取字幕错误:', error);
      message.error('获取字幕内容时发生错误');
      navigate('/dashboard');
    } finally {
      setLoading(false);
    }
  };

  // 在组件加载时获取规则列表
  useEffect(() => {
    fetchRules();
  }, []);

  // 修改应用规则的处理函数
  const handleReplace = async () => {
    if (!selectedRuleId) {
      message.warning('请先选择替换规则');
      return;
    }

    try {
      setApplyingRule(true);
      const response = await fetch('/api/subtitles/replace', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          subtitleId: id,
          ruleId: selectedRuleId,
          currentContent: modifiedContent // 传递当前内容而不是原始内容
        }),
      });

      const data = await response.json();
      if (data.success) {
        setModifiedContent(data.modifiedContent);
        message.success('替换规则应用成功');
      } else {
        message.error(data.message || '替换失败');
      }
    } catch (error) {
      console.error('替换错误:', error);
      message.error('应用替换规则时发生错误');
    } finally {
      setApplyingRule(false);
    }
  };

  // 添加离开页面前的确认
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (modifiedContent !== lastSavedContent) {
        e.preventDefault();
        e.returnValue = ''; // Chrome 需要设置 returnValue
        return ''; // 返回空字符串以显示浏览器默认提示
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [modifiedContent, lastSavedContent]);

  // 添加处理返回的函数
  const handleReturn = () => {
    if (modifiedContent !== lastSavedContent) {
      Modal.confirm({
        title: '确认返回',
        content: '你有未保存的修改，确定要返回吗？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => navigate('/dashboard')
      });
    } else {
      navigate('/dashboard');
    }
  };

  // 添加获取规则列表的函数
  const fetchRules = async () => {
    try {
      const response = await fetch('/api/subtitles/rules', {
        credentials: 'include'
      });
      const data = await response.json();
      if (data.success) {
        setRules(data.rules);
      } else {
        message.error('获取替换规则失败');
      }
    } catch (error) {
      console.error('获取规则错误:', error);
      message.error('获取替换规则失败');
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const response = await fetch(`/api/subtitles/${id}/save`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          modifiedContent: modifiedContent, // 确保使用正确的字段名
          action: 'save' // 明确指定保存操作
        }),
      });

      const data = await response.json();
      if (data.success) {
        setLastSavedContent(modifiedContent); // 更新最后保存的内容
        message.success('保存成功');
      } else {
        message.error(data.message || '保存失败');
      }
    } catch (error) {
      console.error('保存错误:', error);
      message.error('保存时发生错误');
    } finally {
      setSaving(false);
    }
  };

  const handleReset = () => {
    Modal.confirm({
      title: '确认重置',
      content: '确定要重置所有修改吗？这将恢复到原始内容。',
      onOk: async () => {
        setModifiedContent(originalContent);
        // 自动保存重置后的内容
        try {
          setSaving(true);
          const response = await fetch(`/api/subtitles/${id}/save`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              modifiedContent: originalContent,  // 修改这里，使用 modifiedContent 而不是 content
              action: 'reset'  // 添加操作类型标识
            }),
          });

          const data = await response.json();
          if (data.success) {
            setLastSavedContent(originalContent);
            message.success('重置成功并已保存');
          } else {
            // 如果保存失败，回滚修改
            setModifiedContent(lastSavedContent);
            message.error(data.message || '重置失败');
          }
        } catch (error) {
          console.error('重置错误:', error);
          // 发生错误时也回滚修改
          setModifiedContent(lastSavedContent);
          message.error('重置时发生错误');
        } finally {
          setSaving(false);
        }
      },
    });
  };

  const handleExport = () => {
    const blob = new Blob([modifiedContent], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `modified_${fileName}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const debounce = (func, wait) => {
    let timeout;
    const debouncedFunc = function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
    
    debouncedFunc.cancel = () => {
      clearTimeout(timeout);
    };
    
    return debouncedFunc;
  };

  const handleScroll = useMemo(() => 
    debounce((source, target) => {
      try {
        if (!source.current || !target.current) return;
        
        const percentage = source.current.scrollTop / 
          (source.current.scrollHeight - source.current.clientHeight);
        
        if (isNaN(percentage)) return;
        
        target.current.scrollTop = percentage * 
          (target.current.scrollHeight - target.current.clientHeight);
      } catch (error) {
        console.error('同步滚动出错:', error);
      }
    }, 10)
  , []);

  // 添加清理效果
  useEffect(() => {
    return () => {
      if (handleScroll.cancel) {
        handleScroll.cancel();
      }
    };
  }, [handleScroll]);


  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" tip="加载中..." />
      </div>
    );
  }

  return (
    <Layout>
      <Content style={{ padding: '24px' }}>
        <div style={{ background: '#fff', padding: '24px', minHeight: 'calc(100vh - 48px)' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
            <Col>
              <Space>
                <h2>字幕编辑器 - {fileName}</h2>
                {modifiedContent !== lastSavedContent && (
                  <span style={{ color: '#ff4d4f' }}>(有未保存的修改)</span>
                )}
              </Space>
            </Col>
            <Col>
              <Space>
                <Select
                  style={{ width: 200 }}
                  placeholder="选择替换规则"
                  value={selectedRuleId}
                  onChange={setSelectedRuleId}
                >
                  {rules.map(rule => (
                    <Option key={rule.id} value={rule.id}>
                      {rule.filename}
                    </Option>
                  ))}
                </Select>
                <Button 
                  type="primary" 
                  icon={<SwapOutlined />}
                  onClick={handleReplace}
                  loading={applyingRule}
                  disabled={!selectedRuleId}
                >
                  应用规则
                </Button>
                <Button 
                  icon={<UndoOutlined />} 
                  onClick={handleReset}
                >
                  重置
                </Button>
                <Button 
                  type="primary" 
                  icon={<SaveOutlined />} 
                  onClick={handleSave}
                  loading={saving}
                >
                  保存修改
                </Button>
                <Button 
                  icon={<ExportOutlined />} 
                  onClick={handleExport}
                >
                  导出
                </Button>
                <Button 
                  icon={<ArrowLeftOutlined />} 
                  onClick={handleReturn}  // 使用新的 handleReturn 函数
                >
                  返回
                </Button>
              </Space>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <div style={{ marginBottom: '10px' }}>
                <strong>原始字幕</strong>
              </div>
              <textarea
                ref={originalRef}
                value={originalContent}
                readOnly
                onScroll={() => {
                  try {
                    handleScroll(originalRef, modifiedRef);
                  } catch (error) {
                    console.error('原始文本滚动错误:', error);
                  }
                }}
                style={{
                  width: '100%',
                  height: 'calc(100vh - 200px)',
                  padding: '10px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  resize: 'none',
                  fontFamily: 'monospace',
                  lineHeight: '1.5',
                  overflowY: 'auto',
                  whiteSpace: 'pre-wrap', // 添加这行以保持格式
                  wordBreak: 'break-all'  // 添加这行以处理长行
                }}
              />
            </Col>
            <Col span={12}>
              <div style={{ marginBottom: '10px' }}>
                <strong>修改后的字幕</strong>
              </div>
              <textarea
                ref={modifiedRef}
                value={modifiedContent}
                onChange={(e) => setModifiedContent(e.target.value)}
                onScroll={() => {
                  try {
                    handleScroll(modifiedRef, originalRef);
                  } catch (error) {
                    console.error('修改文本滚动错误:', error);
                  }
                }}
                style={{
                  width: '100%',
                  height: 'calc(100vh - 200px)',
                  padding: '10px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  resize: 'none',
                  fontFamily: 'monospace',
                  lineHeight: '1.5',
                  overflowY: 'auto',
                  whiteSpace: 'pre-wrap', // 添加这行以保持格式
                  wordBreak: 'break-all'  // 添加这行以处理长行
                }}
              />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}

export default SubtitleEditor;